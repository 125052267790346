import { Application } from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'

import $ from "jquery"

const application = Application.start()
// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Controller files must be named *_controller.js.
const controllers  = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

$(document).on('click', 'form .remove_record', function (event) {
  $(this).prev('input[type=hidden]').val('1');
  $(this).closest('.assoc-row').hide();
  return event.preventDefault();
});

$(document).on('click', 'form .add_fields', function (event) {
  const time = new Date().getTime();
  const regexp = new RegExp($(this).data('id'), 'g');
  $(this).before($(this).data('fields').replace(regexp, time));
  return event.preventDefault();
});
