import {Controller} from "@hotwired/stimulus"

export default class DefinitionController extends Controller {
  static targets = ["form", "vessel", "carrier"];

  connect() {
    this.hideForms();
  }

  showForm(event) {
    const selectedForm = event.target.value;

    this.hideForms();

    // Show the selected form container
    const selectedFormContainer = this.formTargets.find(
      (form) => form.dataset.form === selectedForm
    );

    selectedFormContainer.classList.remove("hidden");
  }

  hideForms() {
    this.formTargets.forEach((form) => {
      form.classList.add("hidden");
    });
  }
}

