import $ from 'jquery'
import {Controller} from "@hotwired/stimulus"

export default class AmendmentRequestController extends Controller {
  static targets = ["requestBox", "sendButton", 'messageSelect', 'customMessage', 'messageIdError', 'customMessageError', 'rejectionForm']

  connect() {
    $("#rejection_message").on('select2:select', function () {
      let event = new Event('change', {bubbles: true})
      this.dispatchEvent(event);
    });

    this.showHideCustomMessage()
  }

  showHideCustomMessage() {
    // Clear previous errors
    if (this.hasMessageIdErrorTarget) {
      this.messageIdErrorTarget.classList.add('d-none')
    }

    if (this.hasCustomMessageErrorTarget) {
      this.customMessageErrorTarget.classList.add('d-none')
    }

    if (this.hasMessageSelectTarget && this.hasCustomMessageTarget && this.messageSelectTarget.value === '0') {
      this.customMessageTarget.classList.remove('d-none')
    } else {
      if (this.hasCustomMessageTarget) {
        this.customMessageTarget.classList.add('d-none')
      }
    }
  }

  validate(event) {
    this.messageIdErrorTarget.classList.add('d-none')
    this.customMessageErrorTarget.classList.add('d-none')

    if (this.messageSelectTarget.value === '') {
      this.messageIdErrorTarget.innerText = 'Rejection message must be selected'
      this.messageIdErrorTarget.classList.remove('d-none')
      return event.preventDefault()
    }

    if (this.messageSelectTarget.value === '0' && this.customMessageTarget.querySelector('input').value.trim().length < 50) {
      this.customMessageErrorTarget.innerText = 'Custom message must be at least 50 characters long'
      this.customMessageErrorTarget.classList.remove('d-none')
      return event.preventDefault()
    }

    this.rejectionFormTarget.submit()
    return event.preventDefault()
  }

  toggleRequestBox(event) {
    this.sendButtonTarget.classList.add('d-none')
    this.requestBoxTarget.classList.remove('d-none');
    document.getElementById('request-form-anchor').scrollIntoView();
    event.preventDefault();
  }

  cancel(event) {
    this.sendButtonTarget.classList.remove('d-none')
    this.requestBoxTarget.classList.add('d-none');
    event.preventDefault();
  }
}
