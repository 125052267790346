import $ from 'jquery'
import select2 from 'select2'
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    select2();
    $(document).on("turbolinks:before-cache", function () {
      $('.select2').each((_, select) => {
        if ($(select).data('select2')) {
          $(select).select2('destroy')
        }
      })
    });
  }

  connect() {
    $('.select2').each((_, select) => {
      if (select.tagName === 'SELECT' && !$(select).data('select2')) {
        $(select).select2({ width: '100%' });
      }
    })
  }
}
