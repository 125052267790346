import $ from 'jquery';
import Rails from "@rails/ujs";
import {Controller} from "@hotwired/stimulus"

export default class UserDefinitionController extends Controller {
  request_url

  static values = {
    kind: String,
  }

  static targets = [
    'vesselSelect',
    'vesselName',
    'vesselNameError',
    'vesselImoCode',
    'vesselImoCodeError',

    'carrierSelect',
    'carrierName',
    'carrierNameError',

    'generalErrors',
    'closeButton',
  ]


  connect() {
    switch (this.kindValue) {
      case 'vessel':
        this.request_url = '/internal_user_api/vessels';
        break;

      case 'carrier':
        this.request_url = '/internal_user_api/carriers';
        break;
    }

    this.clearForm();
  }

  clearForm() {
    this.#clearForm();
  }

  async createCarrier(e) {
    e.preventDefault();
    this.#hideErrors();

    const carrier_name = this.carrierNameTarget.value.trim();

    if (!carrier_name) {
      this.#showInputError(this.carrierNameErrorTarget, 'Carrier name is required.');
      return;
    }

    const carriers_response = await fetch(`${this.request_url}?name=${carrier_name}`, {
      method: 'GET',
      headers: this.#headers()
    })
    const carriers_json = await carriers_response.json();

    // If carrier already exists, return
    if (carriers_json.result === 'success') {
      switch (carriers_json.kind) {
        case 'approved':
          this.#showGeneralError('Carrier could not be created since it already exists.');
          return;

        case 'user defined':
          this.#showGeneralError('Carrier could not be created since it already created by you and pending approval.');
          return;
      }

      return;
    }

    // Create carrier
    const create_carrier_response = await fetch(this.request_url, {
      method: 'POST',
      headers: this.#headers(),
      body: JSON.stringify({
        carrier: {name: carrier_name}
      })
    })
    const create_carrier_json = await create_carrier_response.json();

    if (create_carrier_json.result === 'success') {
      // Add carrier to select options and select it
      const {carrier} = create_carrier_json;
      const newCarrier = new Option(carrier.name, carrier.id, false, true);
      $(this.carrierSelectTarget).append(newCarrier).trigger('change');

      // Close the modal
      this.closeButtonTarget.click();
    } else {
      this.#showGeneralError(`Carrier could not be created. Error: ${create_carrier_json.message}`);
    }
  }

  async createVessel(e) {
    e.preventDefault();
    this.#hideErrors();

    const vessel_name = this.vesselNameTarget.value.trim();
    const vessel_imo_code = this.vesselImoCodeTarget.value.trim();

    if (!vessel_name || !vessel_imo_code) {
      !vessel_name && this.#showInputError(this.vesselNameErrorTarget, 'Vessel name is required.');
      !vessel_imo_code && this.#showInputError(this.vesselImoCodeErrorTarget, 'Vessel IMO code is required.');

      return;
    }

    const vessels_response = await fetch(`${this.request_url}?imo_code=${vessel_imo_code}`, {
      method: 'GET',
      headers: this.#headers()
    })
    const vessels_json = await vessels_response.json();

    // If vessel already exists, return
    if (vessels_json.result === 'success') {
      switch (vessels_json.kind) {
        case 'approved':
          this.#showGeneralError('Vessel could not be created since there is another vessel with the same IMO code.');
          return;

        case 'user defined':
          this.#showGeneralError('Vessel could not be created since it already created by you and pending approval.');
          return;
      }

      return;
    }

    // Create vessel
    const create_vessel_response = await fetch(this.request_url, {
      method: 'POST',
      headers: this.#headers(),
      body: JSON.stringify({
        vessel: {
          name: vessel_name,
          imo_code: vessel_imo_code
        }
      })
    })
    const create_vessel_json = await create_vessel_response.json();

    if (create_vessel_json.result === 'success') {
      // Add vessel to select options and select it
      const {vessel} = create_vessel_json;
      const newVessel = new Option(vessel.name, vessel.id, false, true);
      $(this.vesselSelectTarget).append(newVessel).trigger('change');

      // Close the modal
      this.closeButtonTarget.click();
    } else {
      this.#showGeneralError(`Vessel could not be created. Error: ${create_vessel_json.message}`);
    }
  }

  #headers() {
    return {
      'Content-Type': 'application/json',
      'X-CSRF_Token': Rails.csrfToken(),
    }
  }

  #showGeneralError(message) {
    if (this.hasGeneralErrorsTarget) {
      this.generalErrorsTarget.innerHTML = message;
      this.generalErrorsTarget.classList.remove('d-none');
    }
  }

  #hideGeneralError() {
    if (this.hasGeneralErrorsTarget) {
      this.generalErrorsTarget.classList.add('d-none');
    }
  }

  #showInputError(target, message) {
    target.innerHTML = message;
    target.classList.remove('d-none');
  }

  #hideVesselErrors() {
    this.vesselNameErrorTarget.classList.add('d-none');
    this.vesselImoCodeErrorTarget.classList.add('d-none');
  }

  #hideCarrierErrors() {
    this.carrierNameErrorTarget.classList.add('d-none');
  }

  #hideErrors() {
    this.#hideGeneralError();

    if (this.kindValue === 'vessel') {
      this.#hideVesselErrors();
    } else if (this.kindValue === 'carrier') {
      this.#hideCarrierErrors();
    }
  }

  #clearForm() {
    if (this.kindValue === 'vessel') {
      this.#clearVesselForm();
    } else if (this.kindValue === 'carrier') {
      this.#clearCarrierForm();
    }

    this.#hideErrors();
  }

  #clearVesselForm() {
    this.vesselNameTarget.value = '';
    this.vesselImoCodeTarget.value = '';
  }

  #clearCarrierForm() {
    this.carrierNameTarget.value = '';
  }
}