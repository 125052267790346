import FormController from './form_controller';
import {formatValueAsCurrency} from "./currency_controller";

const CREDIT_CARD_FEE_PERCENTAGE = 0.065;

const getBankTransferForm = () => document.getElementById('bank-transfer-form');
const getCreditCardForm = () => document.getElementById('credit-card-form');

const getBankTransferButton = () =>
  document.getElementById('bank-transfer-btn');
const getCreditCardButton = () => document.getElementById('credit-card-btn');

const convertToDarkButton = element => {
  element.classList.remove('btn-dashboard');
  !element.classList.contains('btn-dark') && element.classList.add('btn-dark');
};

const convertToLightButton = element => {
  element.classList.remove('btn-dark');
  !element.classList.contains('btn-dashboard') &&
    element.classList.add('btn-dashboard');
};

const validationRules = {
  user_request_amount: {
    isValid: input =>
      !!input.value && Number(input.value.replace(',', '')).toFixed(2) >= 10,
    errorMessage:
      'Amount must be valid decimal number and equal or greater than 10.00',
  }
};

export default class DepositRequestController extends FormController {
  isPayWithCreditCardActive = false;

  static targets = [
    'confirmBox', 'refuseBox', 'confirmButton', 'refuseButton', 'requestMessage', 'fileUpload',
    'sendRequestButton', 'payWithCreditCardButton', 'amount', 'amountWithFee', 'amountWithFeeBox',
  ];

  constructor(context) {
    super(context, validationRules, ['user_request_amount']);
    if (this.hasAmountWithFeeBoxTarget) {
      this.onBankTransferClick();
    }
  }

  toggleConfirm(event) {
    this.confirmButtonTarget.classList.add('d-none');
    this.refuseButtonTarget.classList.add('d-none');
    this.refuseBoxTarget.classList.add('d-none');
    this.confirmBoxTarget.classList.remove('d-none');
    event.preventDefault();
  }

  toggleRefuse(event) {
    this.confirmButtonTarget.classList.add('d-none');
    this.refuseButtonTarget.classList.add('d-none');
    this.confirmBoxTarget.classList.add('d-none');
    this.refuseBoxTarget.classList.remove('d-none');
    event.preventDefault();
  }

  cancel(event) {
    this.confirmButtonTarget.classList.remove('d-none');
    this.refuseButtonTarget.classList.remove('d-none');
    this.refuseBoxTarget.classList.add('d-none');
    this.confirmBoxTarget.classList.add('d-none');
    event.preventDefault();
  }

  onBankTransferClick() {
    this.amountWithFeeBoxTarget.classList.add('d-none');
    this.payWithCreditCardButtonTarget.classList.add('d-none');

    this.requestMessageTarget.classList.remove('d-none');
    this.fileUploadTarget.classList.remove('d-none');
    this.sendRequestButtonTarget.classList.remove('d-none');

    this.isPayWithCreditCardActive = false;
    window.setTimeout(() => this.amountTarget.focus(), 0);

    convertToDarkButton(getBankTransferButton());
    convertToLightButton(getCreditCardButton());
  }

  onCreditCardClick() {
    this.amountWithFeeBoxTarget.classList.remove('d-none');
    this.payWithCreditCardButtonTarget.classList.remove('d-none');

    this.requestMessageTarget.classList.add('d-none');
    this.fileUploadTarget.classList.add('d-none');
    this.sendRequestButtonTarget.classList.add('d-none');

    this.isPayWithCreditCardActive = true;
    this.onAmountChange({target: {value: this.amountTarget.value}});

    window.setTimeout(() => this.amountTarget.focus(), 0);

    convertToDarkButton(getCreditCardButton());
    convertToLightButton(getBankTransferButton());
  }

  onAmountChange(event) {
    if (!this.isPayWithCreditCardActive) {
      return
    }

    let value = event.target.value;
    if (value.endsWith('.00')) {
      value = value.replace('.00', '');
    }
    value = value.replaceAll(/[^0-9\-]/g, '');

    const parsedValue = parseFloat(value);

    if (value === '' || parsedValue === 0) {
      this.amountWithFeeTarget.value = '';
      return;
    }

    if (Number.isNaN(parsedValue) || parsedValue < 0) {
      this.amountWithFeeTarget.value = 'please enter a positive amount';
      return;
    }

    this.amountWithFeeTarget.value = formatValueAsCurrency((parsedValue * (1.0 + CREDIT_CARD_FEE_PERCENTAGE)).toString());
  }

  payWithCreditCard(event) {
    event.preventDefault();
    const isAmountValid = this.validateForm();
    if (!isAmountValid) {
      this.onCreditCardClick();
      return;
    }

    // Re-check account before redirecting to stripe payment page to make sure the amount is still available and valid
    // (e.g. user has modified the amount in the browser)
    let amount = this.amountTarget.value;
    if (amount.endsWith('.00')) {
      amount = amount.replace('.00', '');
    }

    amount = amount.replaceAll(/[^0-9\-.]/g, '');
    const parsedAmount = parseFloat(amount);
    if (amount === '' || parsedAmount === 0 || Number.isNaN(parsedAmount) || parsedAmount < 0) {
      throw new Error('Invalid amount');
    }

    // To avoid rendering the bank transfer form when the user clicks on the back button
    this.onCreditCardClick();
    location.href = '/payments/new?amount=' + parsedAmount.toString();
  }

  submit(event) {
    if (this.isPayWithCreditCardActive) {
      this.payWithCreditCard(event)
      return;
    }

    super.submit(event);
  }
}
