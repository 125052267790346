import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class FinancialClearingChartsController extends Controller {
  static targets = [
    'validationsChart',
    'invoiceTotalChart',
    'userRequestsChart',
    'dailyECTNPerformanceChart',
    'importValueChart',
  ];

  async connect() {
    await this.loadCharts();
  }

  async loadCharts() {
    await Promise.all([
      this.loadValidationsChart(),
      this.loadInvoiceTotalChart(),
      this.loadUserRequestsChart(),
      this.loadDailyECTNPerformanceChart(),
      this.loadImportValueChart(),
    ]);
  }

  async loadImportValueChart() {
    if (!this.hasImportValueChartTarget) {
      return Promise.resolve(false);
    }

    const response = await fetch(
      '/financial_clearing/stats/total_cost_of_goods.json',
    );
    const data = await response.json();

    new Chart(this.importValueChartTarget, {
      type: 'line',
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: 'Import Value',
            data: Object.values(data),
            backgroundColor: '#1AAF5D',
            borderColor: '#1AAF5D',
            borderWidth: 3,
          },
        ],
      },
    });
  }

  async loadValidationsChart() {
    if (!this.hasValidationsChartTarget) {
      return Promise.resolve(false);
    }

    const response = await fetch('/financial_clearing/stats/validations.json');
    const data = await response.json();

    new Chart(this.validationsChartTarget, {
      type: 'line',
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            label: 'Entries Count',
            data: Object.values(data),
            backgroundColor: '#A67F8E',
            borderColor: '#A67F8E',
            borderWidth: 3,
          },
        ],
      },
    });
  }

  async loadInvoiceTotalChart() {
    if (!this.hasInvoiceTotalChartTarget) {
      return Promise.resolve(false);
    }

    const response = await fetch(
      '/financial_clearing/stats/invoice_total.json',
    );
    const data = await response.json();

    const total_invoice = [];
    const total_entries = [];

    Object.values(data).forEach(value => {
      total_invoice.push(value['invoice_total']);
    });

    Object.values(data).forEach(value => {
      total_entries.push(value['entries']);
    });

    new Chart(this.invoiceTotalChartTarget, {
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            type: 'line',
            label: 'Invoice Total',
            data: total_invoice,
            backgroundColor: '#009FB7',
            borderColor: '#009FB7',
            borderWidth: 3,
            yAxisID: 'y1',
          },
          {
            type: 'line',
            label: 'Entry Total',
            data: total_entries,
            backgroundColor: '#696773',
            borderColor: '#696773',
            borderWidth: 3,
            yAxisID: 'y2',
          }
        ],
      },
      options: {
        responsive: true,
        scales: {
          y1: {
            display: true,
            ticks: {
              callback: function (value, index, values) {
                if (value < 1000) {
                  return Number(value).toString();
                }

                return Number((value / 1000).toString()) + 'K'; //pass tick values as a string into Number function
              }
            }
          },
          'y2': {
            display: false,
            position: 'right',
            beginAtZero: true,
          },
          x: {
            title: {
              display: true,
              text: 'Week Number'
            },
            grid: {
              display: false,
            },
            ticks: {
              precision: 0,
              stepSize: 1,
            }
          },
        }
      },
    });
  }

  async loadUserRequestsChart() {
    if (!this.hasUserRequestsChartTarget) {
      return Promise.resolve(false);
    }

    const response = await fetch(
      '/financial_clearing/stats/user_requests.json',
    );
    const data = await response.json();
    
    const user_requests_count = [];
    const user_requests_amount = [];

    Object.values(data).forEach(value => {
      user_requests_count.push(value['Count']);
    });

    Object.values(data).forEach(value => {
      user_requests_amount.push(value['Amount']);
    });

    new Chart(this.userRequestsChartTarget, {
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            type: 'line',
            label: "Total Amount",
            data: user_requests_amount,
            backgroundColor: '#6C534E',
            borderColor: '#6C534E',
            borderWidth: 3,
            yAxisID: 'y1',
          },
          {
            type: 'line',
            label: "Total Count",
            data: user_requests_count,
            backgroundColor: '#1AAF5D',
            borderColor: '#1AAF5D',
            borderWidth: 3,
            yAxisID: 'y2',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y1: {
            display: true,
            ticks: {
              callback: function (value, index, values) {
                if (value < 1000) {
                  return Number(value).toString();
                }

                return Number((value / 1000).toString()) + 'K'; //pass tick values as a string into Number function
              },
            },
          },
          y2: {
            display: false,
          },
          x: {
            title: {
              display: true,
              text: 'Week Number',
            },
            grid: {
              display: false,
            },
            ticks: {
              precision: 0,
              stepSize: 1,
            },
          },
        },
      },
    });
  }

  async loadDailyECTNPerformanceChart() {
    if (!this.hasDailyECTNPerformanceChartTarget) {
      return Promise.resolve(false);
    }

    const response = await fetch(
      '/financial_clearing/stats/daily_ectn_performance.json',
    );
    const data = await response.json();

    const daily_ectn_count = [];
    const daily_ectn_cost = [];

    Object.values(data).forEach(value => {
      daily_ectn_count.push(value['entries']);
    });

    Object.values(data).forEach(value => {
      daily_ectn_cost.push(value['total_cost']);
    });

    new Chart(this.dailyECTNPerformanceChartTarget, {
      data: {
        labels: Object.keys(data),
        datasets: [
          {
            type: 'line',
            label: "Total Cost",
            data: daily_ectn_cost,
            backgroundColor: '#6C534E',
            borderColor: '#6C534E',
            borderWidth: 3,
            yAxisID: 'y1',
          },
          {
            type: 'line',
            label: "Total Entries",
            data: daily_ectn_count,
            backgroundColor: '#2C1A1D',
            borderColor: '#2C1A1D',
            borderWidth: 3,
            yAxisID: 'y2',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y1: {
            display: true,
            ticks: {
              callback: function (value, index, values) {
                if (value < 1000) {
                  return Number(value).toString();
                }

                return Number((value / 1000).toString()) + 'K';
              },
            },
          },
          y2: {
            display: true,
            position: 'right',
            beginAtZero: true,
            ticks: {
              callback: function (value, index, values) {
                return Number(value.toString());
              },
            },
          },
        },
      },
    });
  }
}
