import $ from "jquery"
import {Controller} from "@hotwired/stimulus"

export default class ConsigneeController extends Controller {
    static targets = ["name", "phone", "address"]

    searchAndPopulate() {
        $.ajax({
            type: "GET",
            url: `/users/${this.nameTarget.dataset.userid}/consignees?name=${this.nameTarget.value.trim()}`,
            success: (data) => {
                if (data) {
                    this.phoneTarget.value = data["phone"]
                    this.addressTarget.value = data["address"]
                } else {
                    this.phoneTarget.value = ""
                    this.addressTarget.value = ""
                }
            }
        })
    }
}
