import {Controller} from "@hotwired/stimulus"

export default class EntrySubmissionController extends Controller {
    invalidWeight(event) {
        if (event.detail[0]) {
            if (!confirm("Check the containers. One container should be 30.000 kg max.\nDo you still want to submit this ECTN?"))
                event.preventDefault()
        } else
            event.preventDefault()
    }
}
