import {Controller} from "@hotwired/stimulus"
import $ from "jquery"
import Rails from "@rails/ujs";

export default class CountrySelectorController extends Controller {
    static targets = ["country", "pol"]

    connect() {
        $("#entry_country").on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
    }

    populate() {
        $.ajax({
            type: "GET",
            url: `/ports/filtered_ports/${this.countryTarget.value}`,
            success: (data) => {
                this.polTarget.innerHTML = "";

                // Add blank option
                const blank_opt = document.createElement("option")
                blank_opt.text = "Port of Loading"
                blank_opt.value = ''
                this.polTarget.appendChild(blank_opt)

                data.forEach((port) => {
                    const opt = document.createElement("option")
                    opt.text = port.name
                    opt.value = port.id
                    this.polTarget.appendChild(opt)
                })
            }
        })
    }
}
