import $ from "jquery"
import {Controller} from "@hotwired/stimulus"

const formatNumber = (value) => {
  // Format number 1000000 to 1,234,567
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatValueAsCurrency = (value) => {
  if (value === '') {
    return '';
  }

  const parsedValue = parseFloat(value.replaceAll(/[^0-9\-]/g, ''));

  if (parsedValue === 0.00 || Number.isNaN(parsedValue)) {
    return '0.00';
  }

  if (value.indexOf('.') >= 0) {
    const decimal_pos = value.indexOf('.');
    let left_side = value.substring(0, decimal_pos);
    let right_side = value.substring(decimal_pos);

    // Add commas to left side of number
    left_side = formatNumber(left_side);

    // Validate right side
    right_side = formatNumber(right_side);

    // Make sure 2 numbers after decimal
    right_side += '00';

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);
    value = left_side + '.' + right_side;
  } else {
    value = formatNumber(value);
    value += '.00';
  }

  if (value[value.length - 1] === ',') {
    value = value.substring(0, value.length - 1);
  }

  return value;
}

export default class extends Controller {
  static targets = ['currencyInput'];

  connect() {
    this.currencyInputTargets.forEach(input => {
      this.formatCurrency({ target: input });
    });
  }

  formatCurrency(e) {
    const input = $(e.target);
    let input_val = input.val();

    // Send updated string to input
    input.val(formatValueAsCurrency(input_val));
  }
}
