import phone from 'phone';

// This regex copied from user.rb model file
const isValidPassword = password => {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,70}$/.test(
    password,
  );
};

const isValidUrl = url => {
  return /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
    url,
  );
};

const isValidEmail = email => {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

const checkPhoneNumber = phoneNumber => {
  return phone(phoneNumber);
};

const fileCountChangeEvent = new Event('fileCountChange');

const FormUtils = {
  isValidPassword,
  isValidUrl,
  isValidEmail,
  checkPhoneNumber,
  fileCountChangeEvent,
};

export default FormUtils;
