import FormUtils from '../helpers/form-utils';
import FormController from './form_controller';

const validationRules = {
  user_first_name: {
    isValid: input => !!input.value,
    errorMessage: "First name can't be blank",
  },
  user_last_name: {
    isValid: input => !!input.value,
    errorMessage: "Last name can't be blank",
  },
  user_email: {
    isValid: input => FormUtils.isValidEmail(input.value),
    errorMessage: 'Email must be a valid email address',
  },
  // user_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  user_country_id: {
    isValid: input => !!input.value,
    errorMessage: "Country can't be blank",
  },
  user_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  user_password: {
    isValid: input => FormUtils.isValidPassword(input.value),
    errorMessage:
      'Password length should be minimum 8 characters and include 1 uppercase, 1 lowercase, 1 digit and 1 special character.',
  },
  // user_password_confirmation: {
  //   isValid: (input, values) => input.value === values.user_password,
  //   errorMessage: "Password confirmation doesn't match password",
  // },
  user_company: {
    isValid: input => !!input.value,
    errorMessage: "Company can't be blank",
  },
  user_website: {
    isValid: input => !input.value || FormUtils.isValidUrl(input.value),
    errorMessage: 'Website must be a valid URL',
  },
  user_license_number: {
    isValid: input => true,
  },
  user_vat: {
    isValid: input => true,
  },
  user_document: {
    isValid: input => Number(input.dataset.fileCount || 0) > 0,
    errorMessage: "Document can't be blank",
  },
};

export default class SignupFormController extends FormController {
  constructor(context) {
    super(context, validationRules);
  }
}
