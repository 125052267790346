import { Controller } from '@hotwired/stimulus';

export default class SameAsController extends Controller {
  static targets = [
    'consigneeName',
    'notifyName',
    'consigneePhone',
    'notifyPhone',
    'consigneeAddress',
    'notifyAddress',
  ];

  sameWithConsignee(event) {
    if (event.currentTarget.checked) {
      this.updateInputValueWith(
        this.consigneeNameTarget,
        this.notifyNameTarget,
      );
      this.updateInputValueWith(
        this.consigneePhoneTarget,
        this.notifyPhoneTarget,
      );
      this.updateInputValueWith(
        this.consigneeAddressTarget,
        this.notifyAddressTarget,
      );
    } else {
      this.notifyNameTarget.value = '';
      this.notifyPhoneTarget.value = '';
      this.notifyAddressTarget.value = '';
    }
  }
  updateInputValueWith(source, target) {
    target.value = source.value;
  }
}
