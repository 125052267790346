import * as ActiveStorage from '@rails/activestorage'
import '@hotwired/stimulus'
import '@hotwired/turbo-rails'
import "jquery"
import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import '~/controllers/index.js'
import '~/helpers/index.js'
import 'select2'
import 'dropzone'

Turbo.session.drive = false

ActiveStorage.start()

Turbo.setProgressBarDelay(1)