import {Controller} from "@hotwired/stimulus";

export const icon = `
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-circle-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
  <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" stroke-width="0" fill="currentColor"></path>
</svg>`

export default class ExistenceCheckerController extends Controller {
  static targets = ["field", "warning"];
  static values = {field: String, id: Number};

  async connect() {
    await this.check();
  }

  async check() {
    const value = this.fieldTarget.value.trim();
    if (!value.length) {
      return
    }

    const existence_response = await fetch(`/internal_user_api/entries?${this.fieldValue}=${value}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    });

    const existence_response_json = await existence_response.json();
    if (existence_response_json.status === 'success' && existence_response_json.entry.id !== this.idValue) {
      this.#showWarning()
    } else {
      this.#hideWarning()
    }
  }

  #showWarning() {
    this.warningTarget.innerHTML = `<div class="d-flex align-items-center">${icon} Already in use! Please make sure it's correct.</div>`;
    this.warningTarget.classList.remove('d-none');
  }

  #hideWarning() {
    this.warningTarget.classList.add('d-none');
  }
}