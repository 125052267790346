import {Controller} from "@hotwired/stimulus";

import {icon} from "./existence_checker_controller";

export default class ExtraOrdinaryAmountCheckController extends Controller {
  static targets = ["amount", "warning"];
  static values = {max: Number};

  connect() {
    this.check();
  }

  check() {
    Number(this.amountTarget.value.replaceAll(',', '')) > this.maxValue ? this.#showWarning() : this.#hideWarning();
  }

  #showWarning() {
    this.warningTarget.innerHTML = `<div class="d-flex align-items-center">${icon} Amount is too high! Make sure it's correct.</div>`;
    this.warningTarget.classList.remove('d-none');
  }

  #hideWarning() {
    this.warningTarget.classList.add('d-none');
  }
}